var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n, entry, increment_url, model, rate_html) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003C!-- es5 only in pug. also only one root node--\u003E\u003Cdiv class=\"rate-show\"\u003E";
if (model.target_type == 'Anime') {
pug_html = pug_html + "\u003Cdiv class=\"rate-number\"\u003E\u003Cspan class=\"current-episodes\"\u003E" + (pug.escape(null == (pug_interp = model.episodes) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (entry.episodes != 0) {
pug_html = pug_html + "\u003Cspan class=\"b-separator\"\u003E\u002F\u003C\u002Fspan\u003E\u003Cspan class=\"total-episodes\"\u003E" + (pug.escape(null == (pug_interp = entry.episodes) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (model.episodes != entry.episodes || model.episodes == 0) {
pug_html = pug_html + "\u003Cspan" + (" class=\"item-add increment\""+pug.attr("data-action", increment_url, true, true)+" data-method=\"post\" data-remote=\"true\" data-type=\"json\"") + "\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"episodes\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('activerecord.attributes.user_rate.episodes')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else
if (model.volumes != 0) {
pug_html = pug_html + "\u003Cdiv class=\"rate-number\"\u003E\u003Cspan class=\"current-episodes\"\u003E" + (pug.escape(null == (pug_interp = model.volumes) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (entry.volumes != 0) {
pug_html = pug_html + "\u003Cspan class=\"b-separator\"\u003E\u002F\u003C\u002Fspan\u003E\u003Cspan class=\"total-episodes\"\u003E" + (pug.escape(null == (pug_interp = entry.volumes) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (model.volumes != entry.volumes || model.volumes == 0) {
pug_html = pug_html + "\u003Cspan" + (" class=\"item-add increment\""+pug.attr("data-action", increment_url, true, true)+" data-remote=\"true\" data-type=\"json\" data-method=\"post\"") + "\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"episodes\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('activerecord.attributes.user_rate.volumes')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"rate-number\"\u003E\u003Cspan class=\"current-episodes\"\u003E" + (pug.escape(null == (pug_interp = model.chapters) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (entry.chapters != 0) {
pug_html = pug_html + "\u003Cspan class=\"b-separator\"\u003E\u002F\u003C\u002Fspan\u003E\u003Cspan class=\"total-episodes\"\u003E" + (pug.escape(null == (pug_interp = entry.chapters) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (model.chapters != entry.chapters || model.chapters == 0) {
pug_html = pug_html + "\u003Cspan" + (" class=\"item-add increment\""+pug.attr("data-action", increment_url, true, true)+" data-remote=\"true\" data-type=\"json\" data-method=\"post\"") + "\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"episodes\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('activerecord.attributes.user_rate.chapters')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + (null == (pug_interp = rate_html) ? "" : pug_interp);
if (model.text_html) {
pug_html = pug_html + "\u003Cdiv class=\"note\"\u003E" + (null == (pug_interp = model.text_html) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined, "entry" in locals_for_with ?
        locals_for_with.entry :
        typeof entry !== 'undefined' ? entry : undefined, "increment_url" in locals_for_with ?
        locals_for_with.increment_url :
        typeof increment_url !== 'undefined' ? increment_url : undefined, "model" in locals_for_with ?
        locals_for_with.model :
        typeof model !== 'undefined' ? model : undefined, "rate_html" in locals_for_with ?
        locals_for_with.rate_html :
        typeof rate_html !== 'undefined' ? rate_html : undefined));
    ;;return pug_html;};
module.exports = template;