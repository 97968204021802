var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n, bar_class, bar_percent, can_vote, model) {
      pug_html = pug_html + "\u003Cdiv class=\"poll-outer\"\u003E\u003C!-- es5 only in pug--\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["b-poll",'is-' + model.width], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"poll\"\u003E\u003Cdiv class=\"name\"\u003E" + (pug.escape(null == (pug_interp = model.name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"text\"\u003E" + (null == (pug_interp = model.text_html) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"poll-variants\"\u003E";
// iterate model.variants
;(function(){
  var $$obj = model.variants;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var poll_variant = $$obj[pug_index0];
if (can_vote) {
pug_html = pug_html + "\u003Cdiv class=\"poll-variant pending\"\u003E\u003Clabel class=\"b-radio\"\u003E\u003Cinput" + (pug.attr("name", 'poll_' + model.id, true, true)+" type=\"radio\""+pug.attr("value", poll_variant.id, true, true)) + "\u003E\u003Cdiv class=\"radio-label\"\u003E" + (null == (pug_interp = poll_variant.label_html) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + ("\u003Cdiv class=\"poll-variant result\"\u003E\u003Cdiv class=\"votes-total\"\u003E" + (pug.escape(null == (pug_interp = poll_variant.votes_total) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"votes-percent\"\u003E" + (pug.escape(null == (pug_interp = bar_percent(poll_variant)) ? "" : pug_interp)) + "%\u003C\u002Fdiv\u003E\u003Clabel\u003E" + (null == (pug_interp = poll_variant.label_html) ? "" : pug_interp));
if (model.vote && model.vote.variant_id == poll_variant.id) {
pug_html = pug_html + "\u003Cdiv class=\"voted-for\"\u003E✓\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Flabel\u003E\u003Cdiv class=\"bar simple horizontal\"\u003E\u003Cdiv class=\"line\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["bar",bar_class(poll_variant)], [false,true]), false, true)+pug.attr("style", pug.style('width: ' + bar_percent(poll_variant) + '%'), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var poll_variant = $$obj[pug_index0];
if (can_vote) {
pug_html = pug_html + "\u003Cdiv class=\"poll-variant pending\"\u003E\u003Clabel class=\"b-radio\"\u003E\u003Cinput" + (pug.attr("name", 'poll_' + model.id, true, true)+" type=\"radio\""+pug.attr("value", poll_variant.id, true, true)) + "\u003E\u003Cdiv class=\"radio-label\"\u003E" + (null == (pug_interp = poll_variant.label_html) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + ("\u003Cdiv class=\"poll-variant result\"\u003E\u003Cdiv class=\"votes-total\"\u003E" + (pug.escape(null == (pug_interp = poll_variant.votes_total) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"votes-percent\"\u003E" + (pug.escape(null == (pug_interp = bar_percent(poll_variant)) ? "" : pug_interp)) + "%\u003C\u002Fdiv\u003E\u003Clabel\u003E" + (null == (pug_interp = poll_variant.label_html) ? "" : pug_interp));
if (model.vote && model.vote.variant_id == poll_variant.id) {
pug_html = pug_html + "\u003Cdiv class=\"voted-for\"\u003E✓\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Flabel\u003E\u003Cdiv class=\"bar simple horizontal\"\u003E\u003Cdiv class=\"line\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["bar",bar_class(poll_variant)], [false,true]), false, true)+pug.attr("style", pug.style('width: ' + bar_percent(poll_variant) + '%'), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (can_vote) {
pug_html = pug_html + "\u003Cdiv class=\"poll-actions\"\u003E\u003Cdiv class=\"vote hidden\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('frontend.polls.vote')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"abstain hidden\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('frontend.polls.abstain')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined, "bar_class" in locals_for_with ?
        locals_for_with.bar_class :
        typeof bar_class !== 'undefined' ? bar_class : undefined, "bar_percent" in locals_for_with ?
        locals_for_with.bar_percent :
        typeof bar_percent !== 'undefined' ? bar_percent : undefined, "can_vote" in locals_for_with ?
        locals_for_with.can_vote :
        typeof can_vote !== 'undefined' ? can_vote : undefined, "model" in locals_for_with ?
        locals_for_with.model :
        typeof model !== 'undefined' ? model : undefined));
    ;;return pug_html;};
module.exports = template;