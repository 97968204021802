var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"b-comments-notifier\""+" style=\"display: none;\""+pug.attr("title", I18n.t('frontend.lib.comments_notifier.number_of_unread_comments'), true, true)) + "\u003E\u003C!-- es5 only in pug. also only one root node--\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined));
    ;;return pug_html;};
module.exports = template;