var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n, destroy_url, image, rel) {
      pug_html = pug_html + "\u003Ca" + (" class=\"b-image\""+pug.attr("href", image.original_url, true, true)+pug.attr("id", image.id, true, true)+pug.attr("rel", rel, true, true)+pug.attr("data-user_id", image.user_id, true, true)+pug.attr("data-tags", image.tags, true, true)) + "\u003E\u003C!-- es5 only in pug. also only one root node--\u003E";
if (destroy_url) {
pug_html = pug_html + "\u003Cdiv class=\"mobile-edit\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"controls\"\u003E\u003Cdiv" + (" class=\"delete\""+pug.attr("title", I18n.t('frontend.images.delete'), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"confirm\""+pug.attr("title", I18n.t('frontend.images.confirm'), true, true)+pug.attr("data-action", destroy_url, true, true)+" data-method=\"delete\" data-remote=\"true\" data-type=\"json\"") + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"cancel\""+pug.attr("title", I18n.t('frontend.images.cancel'), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cimg" + (pug.attr("src", image.preview_url, true, true)) + "\u003E\u003C\u002Fa\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined, "destroy_url" in locals_for_with ?
        locals_for_with.destroy_url :
        typeof destroy_url !== 'undefined' ? destroy_url : undefined, "image" in locals_for_with ?
        locals_for_with.image :
        typeof image !== 'undefined' ? image : undefined, "rel" in locals_for_with ?
        locals_for_with.rel :
        typeof rel !== 'undefined' ? rel : undefined));
    ;;return pug_html;};
module.exports = template;