var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n, score) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"b-rate rateable\""+pug.attr("data-notices", I18n.t('activerecord.attributes.user_rate.scores'), true, true)) + "\u003E\u003C!-- es5 only in pug. also only one root node--\u003E\u003Cdiv class=\"stars-container\"\u003E\u003Cdiv class=\"hoverable-trigger\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["stars","score",'score-' + score], [false,false,true]), false, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"stars hover\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"stars background\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"text-score\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["score-value",'score-' + score], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = score) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"score-notice\"\u003E";
if (score > 0) {
pug_html = pug_html + (pug.escape(null == (pug_interp = I18n.t('activerecord.attributes.user_rate.scores.' + score)) ? "" : pug_interp));
}
else {
pug_html = pug_html + "&nbsp;";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined, "score" in locals_for_with ?
        locals_for_with.score :
        typeof score !== 'undefined' ? score : undefined));
    ;;return pug_html;};
module.exports = template;