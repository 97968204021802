var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n, isAutocomplete) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003C!-- es5 only in pug--\u003E";
if (isAutocomplete) {
pug_html = pug_html + "\u003Cdiv class=\"nothing_found\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('frontend.search.nothing_found')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"b-nothing_here\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('frontend.search.nothing_found')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined, "isAutocomplete" in locals_for_with ?
        locals_for_with.isAutocomplete :
        typeof isAutocomplete !== 'undefined' ? isAutocomplete : undefined));
    ;;return pug_html;};
module.exports = template;