var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n, currentMode, hasIndex) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003C!-- es5 only in pug. also only one root node--\u003E";
if (hasIndex) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["search-mode",(currentMode === 'index' ? 'active' : '')], [false,true]), false, true)+" data-mode=\"index\"") + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('frontend.search.mode.index')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
// iterate ['anime', 'manga', 'ranobe', 'character', 'person']
;(function(){
  var $$obj = ['anime', 'manga', 'ranobe', 'character', 'person'];
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var mode = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["search-mode",(currentMode === mode ? 'active' : '')], [false,true]), false, true)+pug.attr("data-mode", mode, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('frontend.search.mode.' + mode)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var mode = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["search-mode",(currentMode === mode ? 'active' : '')], [false,true]), false, true)+pug.attr("data-mode", mode, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('frontend.search.mode.' + mode)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined, "currentMode" in locals_for_with ?
        locals_for_with.currentMode :
        typeof currentMode !== 'undefined' ? currentMode : undefined, "hasIndex" in locals_for_with ?
        locals_for_with.hasIndex :
        typeof hasIndex !== 'undefined' ? hasIndex : undefined));
    ;;return pug_html;};
module.exports = template;