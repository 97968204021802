var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n, destroy_url, extended_html, form_method, form_url, model, statuses, user_id) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003C!-- es5 only in pug. also only one root node--\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["b-add_to_list",model.status], [false,true]), false, true)) + "\u003E\u003Cform" + (pug.attr("action", form_url, true, true)+pug.attr("data-method", form_method, true, true)+" data-remote=\"true\" data-type=\"json\"") + "\u003E\u003Cinput type=\"hidden\" name=\"frontend\" value=\"1\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"user_rate[user_id]\""+pug.attr("value", user_id, true, true)) + "\u003E\u003Cinput" + (" type=\"hidden\" name=\"user_rate[target_id]\""+pug.attr("value", model.target_id, true, true)) + "\u003E\u003Cinput" + (" type=\"hidden\" name=\"user_rate[target_type]\""+pug.attr("value", model.target_type, true, true)) + "\u003E\u003Cinput" + (" type=\"hidden\" name=\"user_rate[status]\""+pug.attr("value", model.status, true, true)) + "\u003E\u003Cinput" + (" type=\"hidden\" name=\"user_rate[score]\""+pug.attr("value", model.score, true, true)) + "\u003E\u003Cdiv class=\"trigger\"\u003E\u003Cdiv class=\"trigger-arrow\"\u003E\u003C\u002Fdiv\u003E";
if (model.id) {
pug_html = pug_html + "\u003Cdiv class=\"edit-trigger\"\u003E\u003Cdiv class=\"edit\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"text\"\u003E\u003Cspan" + (" class=\"status-name\""+pug.attr("data-text", statuses[model.status], true, true)) + "\u003E\u003C\u002Fspan\u003E";
if (model.score > 0) {
pug_html = pug_html + "\u003Cspan\u003E&nbsp;&nbsp;&ndash;&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = model.score) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (" class=\"text add-trigger\""+pug.attr("data-status", model.status, true, true)) + "\u003E\u003Cdiv class=\"plus\"\u003E\u003C\u002Fdiv\u003E\u003Cspan" + (" class=\"status-name\""+pug.attr("data-text", I18n.t('frontend.user_rates.button.add_to_list'), true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"expanded-options\"\u003E";
// iterate statuses
;(function(){
  var $$obj = statuses;
  if ('number' == typeof $$obj.length) {
      for (var status = 0, $$l = $$obj.length; status < $$l; status++) {
        var title = $$obj[status];
if (!model.id || model.status != status) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"option add-trigger\""+pug.attr("data-status", status, true, true)) + "\u003E\u003Cdiv class=\"text\"\u003E\u003Cspan" + (" class=\"status-name\""+pug.attr("data-text", title, true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var status in $$obj) {
      $$l++;
      var title = $$obj[status];
if (!model.id || model.status != status) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"option add-trigger\""+pug.attr("data-status", status, true, true)) + "\u003E\u003Cdiv class=\"text\"\u003E\u003Cspan" + (" class=\"status-name\""+pug.attr("data-text", title, true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

if (model.id) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"option remove-trigger\""+pug.attr("data-action", destroy_url, true, true)+" data-method=\"delete\" data-remote=\"true\" data-type=\"json\""+pug.attr("value", I18n.t('frontend.user_rates.button.remove_from_my_list'), true, true)) + "\u003E\u003Cdiv class=\"text\"\u003E\u003Cspan" + (" class=\"status-name\""+pug.attr("data-text", I18n.t('frontend.user_rates.button.remove_from_my_list'), true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E" + (null == (pug_interp = extended_html) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined, "destroy_url" in locals_for_with ?
        locals_for_with.destroy_url :
        typeof destroy_url !== 'undefined' ? destroy_url : undefined, "extended_html" in locals_for_with ?
        locals_for_with.extended_html :
        typeof extended_html !== 'undefined' ? extended_html : undefined, "form_method" in locals_for_with ?
        locals_for_with.form_method :
        typeof form_method !== 'undefined' ? form_method : undefined, "form_url" in locals_for_with ?
        locals_for_with.form_url :
        typeof form_url !== 'undefined' ? form_url : undefined, "model" in locals_for_with ?
        locals_for_with.model :
        typeof model !== 'undefined' ? model : undefined, "statuses" in locals_for_with ?
        locals_for_with.statuses :
        typeof statuses !== 'undefined' ? statuses : undefined, "user_id" in locals_for_with ?
        locals_for_with.user_id :
        typeof user_id !== 'undefined' ? user_id : undefined));
    ;;return pug_html;};
module.exports = template;