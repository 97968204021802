var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (I18n, achievement, window) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["achievement",achievement.event], [false,true]), false, true)) + "\u003E\u003C!-- es5 only in pug. also only one root node--\u003E\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"title\"\u003E\u003Ca" + (pug.attr("href", window.SHIKI_USER.url + '/achievements', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('frontend.achievements.title.' + achievement.event)) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"name\"\u003E\u003Cdiv class=\"icon\"\u003E\u003C\u002Fdiv\u003E\u003Ca" + (pug.attr("href", '/achievements/' + achievement.neko_id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = achievement.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
if (achievement.level) {
pug_html = pug_html + "\u003Cspan class=\"level\"\u003Eур. " + (pug.escape(null == (pug_interp = achievement.level) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"image\"\u003E\u003Cimg" + (pug.attr("src", achievement.image, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "I18n" in locals_for_with ?
        locals_for_with.I18n :
        typeof I18n !== 'undefined' ? I18n : undefined, "achievement" in locals_for_with ?
        locals_for_with.achievement :
        typeof achievement !== 'undefined' ? achievement : undefined, "window" in locals_for_with ?
        locals_for_with.window :
        typeof window !== 'undefined' ? window : undefined));
    ;;return pug_html;};
module.exports = template;